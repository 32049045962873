import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, switchMap, take } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EncryptService } from '../encrypt/encrypt.service';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { SessionStorageKey } from '../session-storage/session-storage.constants';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  sessionId = '';

  constructor(private http: HttpClient, private encrypt: EncryptService, private sessionStorage: SessionStorageService) { }

  updateUrl(url: string): string {
    return `${ environment.apiUrl }/${ url }`;
  }

  get<T>(url: string): Observable<T> {
    return this.setHeaders().pipe(
      take(1),
      switchMap(newHeaders => this.http.get<T>(this.updateUrl(url), { headers: newHeaders }))
    );
  }

  post<T>(url: string, payload: any): Observable<T> {
    return this.setHeaders().pipe(
      take(1),
      switchMap(newHeaders => this.http.post<T>(this.updateUrl(url), payload, { headers: newHeaders }))
    );
  }

  private setHeaders(): Observable<HttpHeaders> {
    const timeStamp = btoa(Date.now().toString());
    return this.getRequestKey(timeStamp).pipe(
      take(1),
      map(key => {
        return new HttpHeaders()
          .append('requestId', timeStamp)
          .append('requestkey', key)
          .append('sessionId', this.sessionId)
      })
    );
  }

  private getRequestKey(timeStamp: string): Observable<string> {
    if (!this.sessionId) {
      this.sessionId = this.sessionStorage.get(SessionStorageKey.SessionId) || btoa(uuidv4());
      this.sessionStorage.set(SessionStorageKey.SessionId, this.sessionId);
    }

    return this.encrypt.encrypt(this.sessionId, timeStamp.slice(0, 16)).pipe(take(1));
  }
}
